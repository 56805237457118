body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.primary-color {
  background-color: var(--color-primary) !important;
}

.swiper-button-prev, .swiper-button-next{
  color: var(--color-primary) !important;
}

.service-item {
  height: 350px !important;
  align-items: center;
}

.service-item  .service-list-item{
  height: 170px !important;
}

.service-item ul {
  list-style-type: none;
  padding: 0;
}

.service-item ul li i{
  color: var(--color-primary);
  margin-right: 4px;

}

.text-default {
  text-decoration: underline;
  text-decoration-color: var(--color-primary);
  text-decoration-skip-ink: none;
  text-underline-offset: 10px;
  
}

.tab-service-item {
  height: 256px !important;
}

.custom-card{
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.custom-card h6{
  position: relative;
  text-decoration: none;
  
}

.custom-card h6::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  top: 23px;
  height: 3px;
  width: 35px; 
  background-color: var(--color-primary);
}

.awr {
  max-height: 0px;
  visibility: hidden;
  transition: all 0.3s linear;
}

.show {
  visibility: visible;
  max-height: 400px;
}

.ref-card .readmore {
  color: var(--color-primary);
}

.ref-card{
  
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.inside-ref-card{
  padding: 10px;
  padding-top: 15px;
}

.filtered-card{
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
}

.faq{
  padding: 20px;
  
}

.faq .faq-body {
  padding-top: 5px;
  margin-left: 10px;
}
.faq .faq-row-wrapper {
  padding: 20px;
  border-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  

}
.faq .faq-body .row-title {
  margin-left: 20px;
}

.faq .faq-title{
  margin-left: 15px;
}

.partener-swapper{
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
}



@media (max-width: 768px) {
  .breadcrumbs {
    background-position: 0px !important;
}
}



@media screen and (max-width: 425px) {
    .services .section-header h2, .constructions .section-header h2{
      font-size: 1.2rem;
    }
    .features .container {
      height: 650px;
    }
    .activity{
      height: 1050px;
    }
    .activity .nav-item .nav-link{
      font-size: 0.6rem !important;
      text-align: center;
    }
    .activity .nav-tabs{
      display: flex;
      align-items: end;
    }
    .post-list-card{
      width: 100% !important;
    }

    .hero .info .container {
      position: relative;
      top: 50px;

    }

    .post-item .post-img{
      width: 100% !important;
    }
    .post-item .post-img img{
      width: 100% !important;
    }
}
